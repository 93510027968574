







































































































.taxonomy-picker .item-picker {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem; // reset style
  font-weight: normal; // reset style

  &.child-expanded.children-display-floating {
    overflow: visible !important; // fix bug with collapse transition resulting in applying overflow hidden
  }

  .item-choice > .content {
    padding: 0 .8em 0 .6em;
  }

  &.display-floating {
    position: absolute;
    left: 100%;
    top: calc(-.5em - 1px);
    z-index: 50000;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 3px 2px 13px 0 rgb(0 0 0 / 5%);

    // Use this kind of margin on children so the animation works better
    & > .item-choice:first-child {
      margin-top: .25em;
    }
    & > .item-choice:last-child {
      margin-bottom: .5em;
    }

    &[data-depth="0"] {
      left: 0;
      top: 30px;
      margin: 0;
      &.no-children {
        right: 0;
      }
      & > .item-choice:first-child {
        margin-top: .5em;
      }
    }
  }
}

.category-picker.display-block {
  display: flex;
}

.option-picker .category-picker.display-block {
  margin-left: 1em;

  border-left: 1px var(--color-light-soft) solid;
  padding-left: .8em;
  font-size: calc(1em - .25px);
}
.option-picker .child-display-block.expanded + .option-choice {
  margin-top: .7em; // add margin on children to improve animation
}

.option-picker {
  margin-top: .25em;
  &[data-depth="0"] {
    margin-top: .4em;
    &.display-block {
      margin-left: -.25em;
    }
  }
}
