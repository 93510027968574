






















































































































.taxonomy-picker-container .taxonomy-picker .category-value {
  width: 100%;

  & > .content {
    height: 1.7rem;
    display: inline-flex;
    align-items: center;
    padding: 0 .5em;
    border-radius: 3px;
    margin: .2em .5em .5em -.3em;
    background-color: #f6f6f6;
    &.invalid {
      background-color: var(--color-error);
      color: var(--color-light);
    }

    .name {
      font-weight: 600;
      font-size: .95em;
    }

    .fa {
      opacity: .4;
      margin-left: .4em;
      font-size: .85em;
    }

    &:hover {
      &:not(.invalid) { background-color: var(--color-light-soft); }

      cursor: pointer;
      .fa { opacity: 1 }
    }
  }

  .children {
    transition: opacity .15s;
  }
  .children.inline {
    display: inline-flex;
    margin-right: .25em;

    .option-value {
      display: inline-flex;
      color: inherit !important;

      & > .content {
        .name {
          font-weight: normal;
        }
        .option-icon {
          display: none;
        }
      }
      &:not(:last-child):after {
        content: "•";
        font-size: .8em;
        margin: 0 0.5rem;
        align-items: center;
        display: inline-flex;
      }
    }
  }
  .button-add {
    display: inline-flex;
    align-items: baseline;
    cursor: pointer;

    .fa {
      font-size: .9em;
    }
    &:not(.opened) .fa {
      opacity: 0.4;
    }
    &:hover .fa {
      opacity: 1;
    }
    &.invalid {
      color: var(--color-error);
      .fa { opacity: 1 !important; }
    }
    .invalid-icon {
      margin-left: .25em;
    }
    .error-message {
      display: none;
      margin-right: .5rem;
    }
  }
  &[data-depth="0"] > .button-add {
    margin-left: .5em;
    margin-bottom: 1rem !important;
  }
  .children:not(.inline) + .button-add {
    padding-left: 2px;
    margin-bottom: .4em;
  }
}
.category-value[data-depth="1"] .category-value {
  font-size: .85rem;
}
.category-value + .category-value > .content {
  margin-top: .25em;
}
.category-value[data-depth="0"] > .children {
  margin: .5rem 0 .5rem .5rem;
}
.category-choice > .content.child-display-floating.expanded ~ .category-value:not(:hover) > .children {
  opacity: .4;
}
.category-choice > .content.child-display-block.expanded ~ .category-value > .children {
  padding-top: .5rem;
  border-top: 1px solid var(--color-light-soft);
}
