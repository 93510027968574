























































































.category-choice {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  &.with-name { margin-top: 0.25em; }

  &.as-btn + .category-choice.as-btn {
    margin-top: .5em;
  }
  &.child-display-block.expanded + .category-choice {
    margin-top: 1em;
  }

  & > .content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 25px;
    white-space: nowrap;
    cursor: pointer;
    transition: top 600s; // small hack to avoid a glitch when top transition to 0 when animating collapse

    .name {
      font-weight: bold;
    }

    &.as-btn {
      border-radius: 3px;
      height: 30px;
      background-color: var(--color-dark-soft);
      color: var(--color-light);

      &.expanded {
        background-color: var(--color-primary);
      }

      .expand-container {
        margin-left: auto;
        .expand-icon {
          color: inherit !important;
        }
      }
    }

    &.mandatory.invalid .name:after {
      content: "*";
      font-weight: normal;
      padding: 0 4px;
      color: var(--color-error);
    }

    &.child-display-floating {
      margin-bottom: 0;
    }
    &:not(.as-btn).child-display-floating.expanded {
      color: var(--color-primary);
    }
  }
}

.category-picker.display-block > .category-choice > .content:not(.as-btn) {
  &.expanded {
    @extend .as-btn;
    background-color: var(--color-light-soft);
    color: var(--color-text);
  }
}

// When all display in block, activate sticky
.taxonomy-picker .category-picker[data-depth="0"].display-block.children-display-block {
  .content.expanded {
    position: sticky;
  }
  .option-choice > .content.expanded {
    height: 30px; // make it bigger for the sticky position
    & + .item-picker {
      margin-top: -.25em;
    }
  }
}
