



























































































































.option-choice {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: .2s margin ease-in-out;

  .content {
    display: flex;
    align-items: center;
    height: 25px;
    transition: .2s height ease-in-out, .2 margin ease-in-out;
    background-color: var(--color-content-background); // for sticky position

    input[type=checkbox] + label {
      font-size: 1em;
      line-height: 0;
      padding-left: 0;
      width: 1.8em;
      display: inline-flex;
      align-items: center;
    }
    input[type=checkbox] + label:before {
      margin: 0;
      position: relative;
    }
    input[type=checkbox]:not(:checked) + label:before
    {
      width: 1em;
      height: 1em;
      border-radius: .15em;
      margin-top: -2px;
    }
    input[type=checkbox]:checked + label:before
    {
      width: .6em;
      height: 1em;
      margin-top: 2px;
    }

    .index {
      opacity: 0;
      width: 0;
      overflow: hidden;
      transition: opacity .2s;
    }

    &.checked .index {
      opacity: 1;
      position: absolute;
      cursor: pointer;
      font-size: 0.65em;
      left: 1em;
      top: -1px;
      width: 1em;
      text-align: center;
      color: var(--color-neutral);
    }

    .title {
      cursor: pointer;
      display: flex;
      flex-grow: 1;
      align-items: center;
      white-space: nowrap;

      input.description {
        height: auto;
        border: 1px solid var(--color-light-soft);
        border-radius: 3px;
        padding: 0em 0.5em;
        margin: 0 0 0 .5em;
        background-color: #00000005;
        font-size: .9em;
        color: var(--color-text-soft);
        &:focus {
          color: var(--color-text);
          box-shadow: none;
          border-color: var(--color-light-soft);
          outline: none;
        }
      }
    }

    &.expanded .title {
      color: var(--color-primary);
    }

    .option-icon {
      width: 1.5em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5em;
    }
    
    .textHelper {
      cursor: help;
      font-weight: normal;
      margin-left: 5px;
      opacity: .6;
    }
  }
}

// turn checkboxes into radio buttons when only once option selectable
.category-choice.single-option > .option-picker > .option-choice > .content input[type=checkbox]:not(:checked) + label:before {
  border-radius: 50%;
}
