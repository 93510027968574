
























































































.children:not(.dragging) > div > .option-value.draggable > .content .drag-zone:hover,
.option-value.sortable-ghost > .content .drag-zone {
  color: var(--color-primary);
  .option-icon {
    color: var(--color-primary) !important;
  }
}
.children:not(.dragging) > div > .option-value > .content .delete-icon:hover {
  font-weight: bold;
  opacity: 1;
  color: var(--color-error);
}

.option-value {
  &.draggable > .content .drag-zone:hover {
    cursor: grab;
  }

  & > .content {
    height: 2rem;
    display: flex;
    align-items: center;

    .drag-zone {
      display: flex;
      align-items: center;
      transition: all .1s;
      flex-shrink: 0;
    }

    .option-icon {
      width: 1.1rem;
      font-size: 1em;
      text-align: center;
      margin-right: 0.5rem;
    }

    .delete-icon {
      padding-left: .25em;
      width: 1.2em;
      cursor: pointer;
      margin-top: 2px;
      opacity: .4;
      transition: all .1s;
    }

    .button-add {
      margin-left: 0.3rem;
      margin-top: 1px;
      font-size: .95em;
    }

    input.description {
      margin: 0 .25em 0 .7em;
      height: auto;
      border-bottom: 1px solid var(--color-light-soft);
      padding: 0;
      margin-top: .15em;
      font-size: .85rem;
      color: var(--color-text-soft);
      &:focus {
        color: var(--color-text);
        box-shadow: none;
        border-color: var(--color-neutral);
        outline: none;
      }
    }
  }

  &:not(.root) .option-value-children {
    margin-left: .5rem;
    margin-bottom: .25em;
    border-left: 1px var(--color-light-soft) solid;
    padding-left: 1.2rem;
  }

  &.root > .option-value-children {
    margin-top: 2rem;
  }
}

.category-value[data-depth="0"]:not(.inline-children) {
  .option-value[data-depth="0"] {
    margin-bottom: 1.5rem;
  }
}
