




















































































































.taxonomy-picker.display-errors .item-choice.invalid {
  & > .content:not(.as-btn):not(.expanded) {
    .title {
      color: var(--color-error);
    }
    [type=checkbox]:checked + label:before {
      border-right-color: var(--color-error);
      border-bottom-color: var(--color-error);
    }
  }
  & > .content.as-btn {
    background-color: var(--color-error);
  }
}
.taxonomy-picker.display-errors .error-message {
  display: inline-flex;
}

.field-container + .field-container.field-taxonomy {
  margin-top: .5rem;
}
.field-container.field-taxonomy + .field-container:not(.field-taxonomy):not(.field-separator) {
  margin-top: 2rem;
}

.categories-info p {
  margin: 0;
}

// Fix conflicts with sonata CSS
.sonata-ba-field .taxonomy-picker {
  .content {
    min-height: 0;
    padding: 0;
    margin: 0;
  }
  input[type=checkbox] {
    margin: -2px 8px 0 0;
    & + label {
      display: none !important;
    }
  }
  input.description {
    border: none;
  }
}
